import React, { useEffect, useState } from 'react';
import { useTheme } from '../../useContextHook/useTheme';
import { useAppContext } from '../../useContextHook/useContextApi';
import { Link, useParams } from 'react-router-dom';
import { fetchApiForYoutubeData } from '../../utils/fetchApi';
import Sidebar from '../SidebarSection/Sidebar';
import { formatPublishTime, formatViewCount, formatDuration } from '../../utils/helper';

const SearchVideoResult = () => {
  const [searchResult, setSearchResult] = useState([]);
  const [order, setOrder] = useState('relevance'); // State for order
  const { isDarkMode } = useTheme();
  const { setLoading } = useAppContext();
  const { searchQuery } = useParams();

  const fetchSearchVideos = async () => {
    setLoading(true);
    try {
      const data = await fetchApiForYoutubeData("search", {
        part: "snippet",
        regionCode: "IN",
        q: searchQuery,
        type: "video",
        maxResults: 20,
        videoDuration: "medium",
        order: order // Use order state
      });
      const videoIds = data.items.map((item) => item.id.videoId).join(",");
      const videoDetailsResponse = await fetchApiForYoutubeData("videos", {
        part: "snippet,contentDetails,statistics",
        id: videoIds
      });

      setSearchResult(videoDetailsResponse?.items);
    } catch (error) {
      console.error(error, 'fetching error searching videos');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSearchVideos();
  }, [searchQuery, order]); // Add order to dependency array

  const handleOrderChange = (e) => {
    setOrder(e.target.value);
  };

  return (
    <div className='flex w-full h-full'>
      <div className='flex-shrink-0 overflow-y-auto'>
        <Sidebar />
      </div>
      <div className={`flex-grow overflow-y-auto ${isDarkMode ? "bg-black text-gray-300" : "bg-white text-gray-800"}`}>
        <div className='p-4'>
          <div className='mb-4'>
            <label htmlFor="order" className='mr-2'>Order by:</label>
            <select id="order" value={order} onChange={handleOrderChange} className={` ${isDarkMode ? "bg-black text-gray-300" : "bg-white text-gray-800"}`}>
              <option value="relevance">Relevance</option>
              <option value="date">Date</option>
              <option value="viewCount">View Count</option>
              <option value="rating">Rating</option>
              <option value="viewCount">View Count</option>
              <option value="title">Title</option>
            </select>
          </div>
          {
            searchResult?.map((result) => (
              <div className='flex flex-col md:flex-row mb-8' key={result.id}>
                <Link to={`/video/${result?.snippet?.categoryId}/${result.id}`}>
                  <img
                    src={result?.snippet?.thumbnails.medium?.url}
                    alt={result?.snippet?.title}
                    className='w-full h-auto object-cover rounded-2xl mb-2'
                  />
                </Link>
                <div className='md:ml-4 md:w-2/3'>
                  <h3 className='text=lg font-bold'>{result?.snippet?.title}</h3>
                  <div className={`text-sm ${isDarkMode ? "text-gray-400" : "text-gray-600"}`}>
                    {result?.snippet?.channelTitle}
                  </div>
                  <div className={`text-xs ${isDarkMode ? "text-gray-400" : "text-gray-600"}`}>
                    {formatViewCount(result?.statistics?.viewCount)} views . {formatPublishTime(result?.snippet?.publishedAt)}
                  </div>
                  <div className={`text-xs p-1 rounded-3xl w-[95px] mt-2  ${isDarkMode ? "text-gray-600 bg-white" : "text-gray-100 bg-black"}`}>
                    Duration: {formatDuration(result?.contentDetails?.duration)}
                  </div>
                  <p className='mt-2'>
                    {result?.snippet?.description.slice(0, 100)}
                  </p>
                    {/* <div className='mt-2'>
                      <Link to={`/channel/${result?.snippet?.channelId}`} className='text-blue-500 hover:underline'>
                        Original Channel
                      </Link>
                    </div> */}
                </div>
              </div>
            ))
          }
        </div>
      </div>
    </div>
  );
};

export default SearchVideoResult;